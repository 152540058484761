// @todo load dynamicly
// see: /studio/schemas/objects/sectionConfigs.js

import {
  Jobs,
  CustomerLogosExtended,
  Subscribe,
  Registration,
  StyleGuide,
  PoweredBy,
  TeamMembers,
  CareersListing,
  CareersBenefits,
  CareersQuoteCluster,
  CareersCarousel,
  CareersHello,
  JobsList,
  Newsletters,
  Events,
  SlackJoin,
  BlogList,
  Hero,
  CompanyNews,
  FeaturedMedia,
  Filters,
  PageHeader,
  PageHeaderPlus,
  ResourcesHeader,
  EventsTeaser,
  ChannelsButtons,
  Integrations,
  PricingPlans,
  PricingComparison,
  Profiles,
  CustomerLogos,
  SlackJoinRedirect,
  DevelopersPanels,
  HubspotWebform,
  PageSectionComponentList,
  AssetRedirect,
  Podcast,
  Divider,
  LinesBg,
  Quotables,
  QuoteWall,
  Videos,
  ResourcesList,
  ResourcesTable,
  CategoryList,
  GetStarted,
  ResourcesSuggestions,
  Tweets,
  IntegrationsList,
  IntegrationsOverview,
  PlaybooksList,
  PlaybooksBlock,
  Badge,
  SearchClient,
  SearchBlog,
  SearchResources,
  SearchHelp,
  SearchResults,
  DocsList,
  Activities,
  QuoteCarousel,
  LandingList,
  SignalsList,
  CustomerStories,
  SignalSources,
  ComparisonIntegrations,
} from "./";

export const getComponentMap = () => {
  const available = new Map();
  available.set("blogList", { component: BlogList });
  available.set("jobs", { component: Jobs, noHeaders: true });
  available.set("customerLogoWall", { component: CustomerLogosExtended });
  available.set("customerLogoWallShort", { component: CustomerLogos });
  available.set("subscribe", { component: Subscribe, noHeaders: true });
  available.set("registration", { component: Registration });
  available.set("styleGuide", { component: StyleGuide });
  available.set("poweredBy", { component: PoweredBy });
  available.set("teamMembers", { component: TeamMembers });
  available.set("careers", { component: CareersListing, noHeaders: true });
  available.set("careersBenefits", { component: CareersBenefits });
  available.set("careersQuoteCluster", {
    component: CareersQuoteCluster,
    noHeaders: true,
  });
  available.set("careersCarousel", {
    component: CareersCarousel,
    noHeaders: true,
  });
  available.set("careersHello", { component: CareersHello });
  available.set("channelsButtons", { component: ChannelsButtons });
  available.set("jobsList", { component: JobsList });
  available.set("newsletters", { component: Newsletters });
  available.set("events", { component: Events });
  available.set("slack", { component: SlackJoin });
  available.set("slackJoinRedirect", { component: SlackJoinRedirect });
  available.set("hero", { component: Hero, noHeaders: true });
  available.set("integrationsList", { component: IntegrationsList });
  available.set("integrationsOverview", { component: IntegrationsOverview });
  available.set("pricingIntegrations", { component: ComparisonIntegrations });
  available.set("tweets", { component: Tweets });
  available.set("companyNews", { component: CompanyNews });
  available.set("featuredMedia", { component: FeaturedMedia, noHeaders: true });
  available.set("filters", { component: Filters });
  available.set("header", { component: PageHeader, noHeaders: true });
  available.set("headerPlus", { component: PageHeaderPlus, noHeaders: true });
  available.set("resourcesHeader", { component: ResourcesHeader });
  available.set("eventsTeaser", { component: EventsTeaser, noHeaders: true });
  available.set("integrations", { component: Integrations, noHeaders: true });
  available.set("pricingPlans", { component: PricingPlans });
  available.set("pricingComparison", {
    component: PricingComparison,
    desktopOnly: true,
  });
  available.set("profiles", { component: Profiles });
  available.set("developersPanels", { component: DevelopersPanels });
  available.set("hubspotWebform", { component: HubspotWebform });
  available.set("pageSectionComponentList", {
    component: PageSectionComponentList,
  });
  available.set("podcast", { component: Podcast });
  available.set("assetRedirect", { component: AssetRedirect });
  available.set("divider", { component: Divider });
  available.set("linesBg", { component: LinesBg });
  available.set("quotables", { component: Quotables });
  available.set("quoteWall", { component: QuoteWall });
  available.set("videos", { component: Videos });
  available.set("resourcesList", { component: ResourcesList });
  available.set("categoryList", { component: CategoryList });
  available.set("resourcesTable", { component: ResourcesTable });
  available.set("playbooksList", { component: PlaybooksList });
  available.set("resourcesSuggestions", { component: ResourcesSuggestions });
  available.set("getStarted", { component: GetStarted, noHeaders: true });
  available.set("playbooksBlock", { component: PlaybooksBlock });
  available.set("badge", { component: Badge, noHeaders: true });
  available.set("searchResources", { component: SearchResources });
  available.set("searchHelp", { component: SearchHelp });
  available.set("searchBlog", { component: SearchBlog });
  available.set("docsList", { component: DocsList });
  available.set("activities", { component: Activities });
  available.set("quoteCarousel", { component: QuoteCarousel });
  available.set("landingList", { component: LandingList });
  available.set("signalsList", { component: SignalsList });
  available.set("signalSources", { component: SignalSources });
  available.set("customerStories", { component: CustomerStories });
  return available;
};

export { SectionWrapper } from "./wrapper";
export { BlogList } from "./snowflake/blog-list";
export { Jobs } from "./snowflake/jobs";
export { CustomerLogos, CustomerLogosExtended } from "./logos";
export { Tweets } from "./snowflake/tweets";
export { Subscribe } from "./subscribe";
export { Registration } from "./registration";
export { PageSectionSimple } from "./simple-content";
export { StyleGuide } from "./style-guide";
export { PageSectionColumns } from "./column-content";
export { PoweredBy } from "./snowflake/powered-by";
export { TeamMembers } from "./snowflake/team-members";
export { CareersListing } from "./snowflake/careers";
export {
  CareersBenefits,
  CareersQuoteCluster,
  CareersCarousel,
  CareersHello,
} from "./snowflake/careers-blocks";
export { JobsList } from "./snowflake/jobs-list";
export { Newsletters } from "./snowflake/newsletters";
export { Events } from "./snowflake/events";
export { SlackJoin, SlackJoinRedirect } from "./snowflake/slack-join";
export { Hero, Activities } from "./snowflake/hero";
export { CompanyNews } from "./snowflake/company-news";
export { FeaturedMedia } from "./featured-media";
export { Filters } from "./filters";
export { PageHeader, PageHeaderPlus } from "./page-header";
export { ResourcesHeader } from "./snowflake/resources-header";
export { EventsTeaser } from "./snowflake/events-teaser";
export { ChannelsButtons } from "./snowflake/channels-buttons";
export { Integrations } from "./snowflake/integrations";
export { PricingPlans } from "./snowflake/pricing-plans";
export { PricingComparison } from "./snowflake/pricing-comparison";
export { Profiles } from "./snowflake/profiles";
export { DevelopersPanels } from "./snowflake/developers";
export { HubspotWebform } from "./hubspot-webform";
export { PageSectionComponentList } from "./component-columns";
export { AssetRedirect } from "./snowflake/download-asset";
export { Podcast } from "./snowflake/podcast";
export { Divider, LinesBg } from "./divider";
export { Quotables } from "./quotables";
export { QuoteWall } from "./quote-wall";
export { Videos } from "./videos";
export { ResourcesList, ResourcesTable } from "./resources-list";
export { CategoryList } from "./categories";
export { ResourcesSuggestions } from "./resources-suggestions";
export { PlaybooksList } from "./playbooks-list";
export { GetStarted } from "./snowflake/get-started-banner";
export { IntegrationsList } from "./snowflake/integrations-list";
export { PlaybooksBlock } from "./playbooks-block";
export { Badge } from "./badge";
export { IntegrationsOverview } from "./snowflake/integrations-overview";
export { SearchBlog, SearchResources, SearchHelp } from "./snowflake/search";
export { DocsList } from "./docs-list";
export { QuoteCarousel } from "./quote-carousel";
export { LandingList } from "./landing-list";
export { SignalsList } from "./signals-list";
export { CustomerStories } from "./snowflake/customer-stories";
export { SignalSources } from "./snowflake/signal-sources";
export { ComparisonIntegrations } from "./snowflake/comparison-integrations";

import React from "react";
import { toPlainText } from "../../../lib/helpers";

export const ResourcesHeader = ({ title, tag, type }) => {
  return (
    <div className="mt-20 pb-8 text-center">
      {/* <EyebrowPill>Resources</EyebrowPill> */}
      <h1 className="text-3xl lg:text-4xl mb-2 font-bold tracking-tight">
        {title || "Explore what's possible with Common Room"}
      </h1>
      {tag && (
        <h2 className="max-w-screen-md mx-auto text-lg font-medium tracking-tight">
          {toPlainText(tag._rawExcerpt)}
        </h2>
      )}
    </div>
  );
};

import React, { useContext } from "react";
import {
  capitalizeFirstLetter,
  cn,
  imageBuilder,
  truncateText,
} from "../../lib/helpers";
import { Byline } from "./byline";
import { CardContext } from "./context";
import { PublishDate } from "./publish-date";
import { pageDocPath } from "../../../helpers";
import { format } from "date-fns";
import { toPlainText } from "../../lib/helpers";
import { Pills } from "./pills";
import { SiteContext } from "../global/site-context";
import { ArrowRight } from "../icon/arrow-left";
import { ImageBuilder } from "../global/image-builder";
import {
  faDesktop,
  faUsers,
  faCodePullRequest,
  faGlobe,
  faMoon,
  faCode,
  faBook,
  faArrowsSplitUpAndLeft,
  faBookOpenReader,
  faVideo,
  faSignal,
  faPodcast,
} from "@fortawesome/free-solid-svg-icons";
import {
  faThumbsUp,
  faIdCard,
  faComments,
  faNewspaper,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import PortableText from "../portableText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SignalPill } from "../../templates/signal";
import { colorMap } from "../global/global-nav";

import * as styles from "./card.module.css";

export const iconMap = new Map([
  ["product", { icon: faDesktop }],
  ["crm", { label: "CRM", icon: faIdCard }],
  ["gtm", { label: "CRM", icon: faIdCard }],
  ["social", { icon: faThumbsUp }],
  ["community", { icon: faUsers }],
  ["code", { label: "Open-source", icon: faCodePullRequest }],
  ["openSource", { label: "Open-source", icon: faCodePullRequest }],
  ["website", { icon: faGlobe }],
  ["forum", { icon: faComments }],
  ["news", { label: "News + events", icon: faNewspaper }],
  ["dark", { label: "Dark funnel", icon: faMoon }],
  ["custom", { icon: faCode }],
  ["email", { icon: faEnvelope }],
  ["post", { label: "Blog post", icon: faNewspaper, color: "peach" }],
  ["playbook", { icon: faArrowsSplitUpAndLeft, color: "mint", size: "md" }],
  ["page", { label: "Guide", icon: faBook, color: "melon" }],
  ["asset", { label: "Guide", icon: faBook, color: "melon" }],
  [
    "story",
    {
      label: "Customer Story",
      icon: faBookOpenReader,
      color: "robin",
    },
  ],
  ["video", { icon: faVideo, color: "dark-10" }],
  [
    "signal",
    {
      icon: faSignal,
      size: "md",
      color: "purple-default",
    },
  ],
  ["podcast", { icon: faPodcast, color: "lemon" }],
]);

export const PODCAST_TAG_ID = "17e46daf-7cde-44a3-b8b3-7ffcf6c7b86b";

export const MetaDetails = ({ card, noImage, isPodcast }) => {
  const displayDate =
    card._type === "event" ? (
      <div className="text-sm text-gray-800">
        Join us{" "}
        {card.recurring
          ? card.recurringPublic
          : format(new Date(card.startDate), "MMMM do, yyyy")}
      </div>
    ) : card.showByline ? (
      <Byline />
    ) : (
      <div className="text-sm text-dark-50 flex">
        <PublishDate />
        {card.readingTimeInMinutes && (
          <>
            <span className="mx-2">&middot;</span>
            {card.readingTimeInMinutes}min read
          </>
        )}
      </div>
    );

  const summary = card.summary || toPlainText(card._rawExcerpt);
  const showSummary =
    !isPodcast &&
    summary &&
    !card.isTall &&
    (card.showSummary || (card.allowSummary && noImage));

  return (
    <>
      {!card.hideDate && card._type === "post" && (
        <div className="mb-4">{displayDate}</div>
      )}
      {showSummary && (
        <div className="overflow-hidden text-sm leading-relaxed">
          <div className={styles.excerpt}>
            {truncateText({ str: summary, titleStr: card.title, max: 250 })}
          </div>
        </div>
      )}
      <div className="mt-2">
        {card.partner && <TinyPills list={["Available via partner"]} />}
      </div>

      {!card.makeThumbnail &&
        !card.hideLabels &&
        (card.categories || card.isVideo) && (
          <div
            className={cn(
              "flex items-center flex-wrap",
              showSummary ? "mt-4" : null
            )}
          >
            <Pills
              tags={card.categories}
              bulkTags={card.tags}
              showSubTags={card.showSubTags}
            />
          </div>
        )}
    </>
  );
};

export const IMAGE_ASPECT_RATIO = 1.9;

export const EMOJI_MAP = new Map([
  ["dark-funnel", "keyhole-emoji.svg"],
  ["community-teams", "emoji-peeps.svg"],
  ["commercial-open-source-software", "emoji-avocado-vertical.svg"],
  ["product-led-growth", "emoji-glasses-face.svg"],
  ["sales", "emoji-arrow-up-right.svg"],
]);

export const Card = ({ className, unlinkedOverlay }) => {
  const card = useContext(CardContext);
  const site = useContext(SiteContext);

  const isPodcast = card.categories?.find((c) => c._id === PODCAST_TAG_ID);

  const noImage = card.hideMainMedia || card.hideImage || card.minimal;
  const showImage =
    !noImage &&
    (card?.mainImage?.asset || card?.cardImage?.asset || card?.imageUrl) &&
    ((card.makeThumbnail && isPodcast) || !card.makeThumbnail);

  const image = card.graphic || card.cardImage || card.mainImage;
  const imageWidth = card.jumboLayout
    ? 1344
    : card.maxColumns === 1
    ? 1152
    : 836;
  const imageHeight = Math.round(imageWidth / IMAGE_ASPECT_RATIO);
  const imageSrc = showImage
    ? card?.imageUrl
      ? card?.imageUrl
      : imageBuilder(image)
          .width(imageWidth)
          .height(imageHeight)
          .quality(99)
          .auto("format")
          .url()
    : false;
  const horizontal = card.horizontalImage && site.isNotMobile;

  const logo = card.showLogo
    ? card?.companies?.[0]?.mainImage || card?.company?.mainImage
    : null;

  const skipLink =
    card.skipLink || (card._type === "signal" && card.status !== "published");

  return (
    <a
      href={skipLink ? null : card.url || pageDocPath(card)}
      className={cn(
        "relative inline-block group rounded-xl transition-card w-full shadow-sm",
        card.isMasonry ? "mb-4 min-h-56" : "h-full",
        showImage ? (horizontal ? "grid grid-cols-1/3-2/3" : "flex-col") : "",
        card.jumboLayout ? "grid-flow-row" : "",
        card.minimal ? "pb-1" : "",
        // card.isTall ? "h-72 md:h-96" : "",
        ["lightLavender", "gradientLavender"].includes(
          site?.doc.backgroundColor
        )
          ? "bg-white"
          : "bg-dark-2",
        className
      )}
      onClick={
        card.attribution
          ? () => {
              site.metrics.logClick(card.attribution.action);
            }
          : null
      }
    >
      {/* general card overlay */}
      {(!skipLink || !!unlinkedOverlay) && (
        <div
          className={cn(
            "absolute top-0 right-0 bottom-0 left-0 z-10 opacity-0 transition-opacity rounded-xl overflow-hidden",
            skipLink && !!unlinkedOverlay
              ? "group-hover:opacity-100 bg-white"
              : "group-hover:opacity-2 bg-black"
          )}
        >
          {unlinkedOverlay}
        </div>
      )}
      {/* {card.hideTitles && showImage && (
        <div className="absolute p-4">{card.title}</div>
      )} */}

      {card.makeThumbnail && (
        <div className={cn("p-4", !isPodcast ? "pb-0" : null)}>
          <TinyPills list={isPodcast ? ["podcast"] : [card._type]} />
        </div>
      )}

      {showImage ? (
        <>
          {horizontal ? (
            <div
              className="order-2 bg-no-repeat bg-cover rounded-r-xl"
              style={{
                backgroundImage: `url('${imageSrc}')`,
                backgroundPosition: "center",
              }}
            ></div>
          ) : (
            <ImageBuilder
              src={imageSrc}
              height={imageHeight}
              width={imageWidth}
              alt={
                card?.cardImage || card?.imageUrl
                  ? card.title
                  : card.mainImage.alt
              }
              className={cn(
                "object-cover object-top relative",
                horizontal
                  ? "rounded-r-xl"
                  : card.makeThumbnail
                  ? "rounded-none"
                  : "rounded-t-xl"
              )}
              style={{ aspectRatio: "1.9" }}
            />
          )}
        </>
      ) : null}
      <div
        className={cn(
          "text‑inherit p-4 relative mx-auto overflow-hidden",
          card.cta ? "h-full pb-10 md:pb-16" : null,
          card._type === "signal"
            ? "md:p-5 md:pr-4 h-full pb-10 md:pb-16"
            : null
        )}
        style={{
          minHeight: "auto",
        }}
      >
        {card.title && (!card.hideTitles || !showImage) && (
          <div className="break-words inline-block w-full mb-2">
            {card.gated && (
              <ImageBuilder
                src="/static/img/icons/lock.svg"
                className="float-right ml-6 w-4"
              />
            )}
            <h3
              className={cn(
                card.minimal
                  ? "text-lg font-semibold"
                  : card.jumboLayout
                  ? "text-xl md:text-2xl"
                  : noImage && !card.makeThumbnail
                  ? "text-2xl font-medium md:leading-tight"
                  : "text-lg md:text-xl font-semibold", // most common
                "tracking-tight"
              )}
            >
              {card.title}
            </h3>
          </div>
        )}
        {logo && !card.makeThumbnail && (
          <ImageBuilder
            image={logo}
            quality={100}
            height={80}
            style={{
              maxHeight: "100px",
              maxWidth: "180px",
            }}
            className="-mt-4"
          />
        )}
        <QuoteDetails card={card} logo={logo} isPodcast={isPodcast} />
        <MetaDetails card={card} noImage={noImage} isPodcast={isPodcast} />
        <SignalDetails card={card} />
        {card.cta ? <CardCta text={card.cta} /> : null}
      </div>
    </a>
  );
};

export const CardCta = ({ text, className }) => (
  <div
    className={cn(
      "mt-4 flex items-center text-link text-sm font-medium absolute bottom-6 left-4",
      className
    )}
  >
    {text}
    <div className="ml-1.5 relative top-px">
      <ArrowRight />
    </div>
  </div>
);

const QuoteDetails = ({ card, logo, isPodcast }) =>
  card.isTall ? (
    card.quote ? (
      <div className={card.makeThumbnail ? "mb-4" : "my-4"}>
        <CardContext.Provider
          value={{
            authors: [
              {
                author: card.quote.authors
                  ? card.quote.authors[0].author
                  : card.quote.author,
              },
            ],
            skipAuthorLink: true,
          }}
        >
          <PortableText blocks={card.quote._rawExcerpt} className="mb-6" />
          {logo && card.makeThumbnail && (
            <ImageBuilder
              image={logo}
              quality={100}
              height={80}
              style={{
                maxHeight: "100px",
                maxWidth: "180px",
              }}
              className="-mt-4"
            />
          )}
          <Byline hideBorder={true} />
        </CardContext.Provider>
      </div>
    ) : !isPodcast ? (
      <div className="overflow-hidden mb-4 text-sm md:text-base leading-relaxed md:leading-relaxed">
        <div className={styles.excerpt}>
          {truncateText({
            str: toPlainText(card._rawExcerpt),
            titleStr: card.title,
            max: 200,
          })}
        </div>
      </div>
    ) : null
  ) : null;

const SignalDetails = ({ card }) =>
  card.sourceType && card.strength ? (
    <>
      <div className="flex flex-wrap my-2">
        <TinyPills list={card.sourceType.slice(0, 2)} />
      </div>
      {card.showIntentTag && (
        <div className="absolute bottom-4 right-4 md:bottom-5 md:right-5 whitespace-nowrap">
          <SignalPill strength={card.strength} />
        </div>
      )}
    </>
  ) : null;

export const TinyPills = ({ list }) => (
  <p className="leading-tight text-xs flex flex-wrap">
    {list.map((p, key) => {
      const icon = iconMap.get(p);

      return (
        <span
          key={key}
          className={cn(
            "flex items-center px-2 py-1 mr-1 mb-1 whitespace-nowrap rounded-md",
            icon?.color ? `bg-${icon.color}` : "text-purple-deep bg-dark-5"
          )}
          style={
            icon?.color
              ? {
                  color: colorMap.get(icon.color),
                }
              : null
          }
        >
          {iconMap.has(p) ? (
            <>
              <FontAwesomeIcon
                icon={iconMap.get(p).icon}
                size={iconMap.get(p).size || "lg"}
                className="mr-1.5"
              />

              {iconMap.get(p).label || capitalizeFirstLetter(p)}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faBook} size="lg" className="mr-2" />
              {capitalizeFirstLetter(p)}
            </>
          )}
        </span>
      );
    })}
  </p>
);

import React, { useContext } from "react";
import { graphql } from "gatsby";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { cn, mapEdgesToNodes, toPlainText } from "../lib/helpers";
import { CardContext } from "../components/card/context";
import { GetStarted, SectionWrapper } from "../components/sections";
import { BackToZone } from "../components/back-to-zone";
import { SiteContext } from "../components/global/site-context";
import PortableText from "../components/portableText";
import { CardGrid } from "../components/card/card-grid";
import { SignalIcon } from "../components/icon/signal";
import { TinyPills } from "../components/card/card";

export const query = graphql`
  query SignalTemplateQuery($id: String!, $categories: [String!]) {
    doc: sanitySignal(id: { eq: $id }) {
      _type
      id
      _id
      title
      slug {
        current
      }
      status
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 12 })
      sourceType
      categories {
        _id
        title
        titlePublic
        slug {
          current
        }
        taxonomy
      }
      strength
      partner
      integrations {
        ...Integration
      }
    }
    relatedSignals: allSanitySignal(
      limit: 6
      sort: { fields: [_createdAt], order: [DESC] }
      filter: {
        slug: { current: { ne: null } }
        status: { eq: "published" }
        id: { ne: $id }
        categories: { elemMatch: { _id: { in: $categories } } }
      }
    ) {
      edges {
        node {
          ...cardNodeSignal
        }
      }
    }
  }
`;

const SignalTemplate = ({ data }) => (
  <LayoutContainer doc={{ ...data?.doc, backgroundColor: "lightLavender" }}>
    <PageHeadMeta
      title={data?.doc.title}
      description={toPlainText(data?.doc._rawExcerpt)}
      image={data?.doc.mainImage}
      doc={data?.doc}
    />
    {data?.doc && (
      <CardContext.Provider value={{ ...data?.doc }}>
        <SignalPage
          {...data?.doc}
          relatedSignals={mapEdgesToNodes(data?.relatedSignals)}
        />
      </CardContext.Provider>
    )}
  </LayoutContainer>
);

export default SignalTemplate;

const CATEGORY_MAP = new Map([["Sales", "Sales-led"]]);

const SignalPage = ({
  title,
  _rawBody,
  sourceType,
  categories,
  strength,
  relatedSignals,
}) => {
  const site = useContext(SiteContext);
  const hasBanner = site.banners.length > 0;

  return (
    <>
      <SectionWrapper
        {...{
          container: "thin",
          noHeaders: true,
          name: "header",
          padding: "bottom",
        }}
      >
        <div className={hasBanner ? "pt-6 md:pt-12" : "pt-16 md:pt-24"}>
          <BackToZone to="/resources/signals/" text="See all signals" />
        </div>
        <h1 className="text-5xl font-semibold mb-8 mr-24">{title}</h1>
        <div className="border border-link p-3 rounded-2xl">
          <ul className="grid md:grid-cols-3 gap-2">
            <li className="bg-white border border-dark-10 p-4 lg:p-6 rounded-lg">
              <p className="text-secondary text-sm font-medium mb-4">
                Go-to-market motion
              </p>
              <div className="flex flex-wrap gap-1 text-sm font-medium">
                {categories
                  .filter((c) => c.taxonomy.includes("motions"))
                  .map((n, i) => (
                    <div
                      className="bg-dark-2 border border-dark-10 py-1 px-2 rounded-md"
                      key={n._id}
                    >
                      <a
                        href={`/resources/signals/?filters=${n.slug.current}`}
                        className="hover:text-link"
                      >
                        {CATEGORY_MAP.get(n.titlePublic) ||
                          n.titlePublic ||
                          n.title}
                      </a>
                    </div>
                  ))}
              </div>
            </li>
            <li className="bg-white border border-dark-10 p-4 lg:p-6 rounded-lg">
              <p className="text-secondary text-sm font-medium mb-4">
                Signal source
              </p>
              <TinyPills list={sourceType} />
            </li>
            <li className="bg-white border border-dark-10 p-4 lg:p-6 rounded-lg">
              <p className="text-secondary text-sm font-medium mb-4">
                Signal strength
              </p>
              <SignalPill strength={strength} />
            </li>
          </ul>
        </div>
      </SectionWrapper>

      {_rawBody && (
        <SectionWrapper container="thin" padding="none">
          <PortableText blocks={_rawBody} />
        </SectionWrapper>
      )}

      {/* use signal category tags to filter related playbooks */}
      <SectionWrapper
        container="thin"
        header="Related signals"
        layout="centered"
      >
        <CardGrid
          nodes={relatedSignals}
          maxColumns={3}
          browseMoreHref="/resources/signals/"
          browseMoreText="See all signals"
          hideEvents={true}
          hideDate={true}
          hideLabels={true}
          showSummary={true}
          hideType={true}
          attribution={{ action: "suggestion" }}
          panels={true}
        />
      </SectionWrapper>

      <SectionWrapper container="large">
        <GetStarted />
      </SectionWrapper>
    </>
  );
};

const STRENGTH_MAP = new Map([
  ["High", { bgColor: "mint", textColor: "mintDark", file: "high.svg" }],
  ["Medium", { bgColor: "peach", textColor: "peachDark", file: "medium.svg" }],
  ["Low", { bgColor: "melonLight", textColor: "red", file: "low.svg" }],
]);

export const SignalPill = ({ strength }) => (
  <div
    className={cn(
      "inline-block px-2 py-1 uppercase rounded-md text-xs",
      `bg-${STRENGTH_MAP.get(strength).bgColor}`,
      `text-${STRENGTH_MAP.get(strength).textColor}`
    )}
  >
    <span className="flex gap-1 items-center">
      <SignalIcon strength={strength} />
      {strength} Intent
    </span>
  </div>
);
